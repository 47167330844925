@keyframes bar {
    0% {
      opacity: 0.2;
      height: 50px;
    }
    33% {
      opacity: 0.2;
      height: 50px;
    }
    66% {
      opacity: 1;
      height: 70px;
    }
    100% {
      opacity: 0.2;
      height: 50px;
    }
  }
  .loader {
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 70px;
    width: 150px;
    display: inline-block;
    vertical-align: middle;
  }
  .loader .bar1 {
    animation: bar 0.6s infinite;
  }
  .loader .bar2 {
    animation: bar 0.6s 0.15s infinite;
  }
  .loader .bar3 {
    animation: bar 0.6s 0.3s infinite;
  }
  .loaderContainer {
    margin: 0 auto;
    position: relative;
    height: 70vh;
    width: 0;
  }