.justify-content-flex-end {
    justify-content: flex-end;
  }
  
  .text-align-center {
    text-align: center;
  }
  
  .align-self-center {
    align-self: center;
  }

  .modal-header-blue{
    color: #11497b;
    font-size: small;
    font-weight: bold;
    }